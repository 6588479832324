.brand-one__carousel {
    padding-left: 48px;
    padding-right: 48px;
    border-bottom: 1px solid #efefef;
    padding-top: 119px;
    padding-bottom: 119px;

    img {
        opacity: 0.3;
        transition: opacity .4s ease;

        &:hover {
            opacity: 1;
        }
    }
}