.header-navigation {
    background-color: transparent;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    padding: 0;
    position: relative;
    background-color: transparent;

    .container {
        background: transparent;
        position: relative;
        display: block;

        .logo-box {
            float: left;
            position: absolute;
            top: 50%;
            left: 15px;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            display: flex;
            align-items: center;
        }

        .navbar-brand {
            height: auto;
            margin: 0;
            line-height: 1;
        }

        .menu-toggler {
            display: none;
        }

        .right-side-box {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 15px;
        }
    }

    .main-navigation {
        float: none;
        text-align: right;

        padding-right: 90px;

        @media (min-width: 1200px) {
            display: block !important;
        }
    }
}

.header-navigation ul.navigation-box {
    margin: 0;
    padding: 0;
    list-style: none;

    li a .sub-nav-toggler {
        display: none;
    }

    >li {
        position: relative;
        padding: 40px 0;
        display: inline-block;
        vertical-align: middle;

        +li {
            margin-left: 70px;

        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        >a {
            padding: 0;
            transition: all .4s ease;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            color: #74727a;
            position: relative;

        }

        &.current>a,
        &:hover>a {
        	color: #2a2833;
            text-shadow: 1px 0 0 rgba($color: #2a2833, $alpha: .8);

        }

        /* Second Level Menu */
        @include dropDownMenuBase($selector: 'sub-menu', $position_top: 100%, $position_left: 0);

        &:hover {
            &:before {
                transform: scale(1, 1);
                transform-origin: left bottom;
            }

            @include dropDownMenuHover($selector: 'sub-menu');
        }

        /* Thrid Level Menu */
        >ul>li {
            @include dropDownMenuBase($selector: 'sub-menu', $position_top: 0%, $position_left: 100%, $right_align_position: 100%);

            &:hover {
                @include dropDownMenuHover($selector: 'sub-menu');
            }

            /* no more nested showen */
            ul {
                display: none;
            }
        }

    }
}

.stricked-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
    opacity: 0;
    transform: translateY(-110%);
    transition: all .4s ease;

    ul.navigation-box>li {
        padding-top: 18px;
        padding-bottom: 18px;
    }

    &.stricky-fixed {
        opacity: 1;
        transform: translateY(0%);
    }
}

.site-header {
    position: relative;
}

.site-header__header-one {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 91;


    .header-navigation .main-navigation {
        padding-right: 0;
        text-align: center;
    }

}

.site-header__header-two {

    .header-navigation ul.navigation-box>li>a {
        color: #ffffff;
    }

    .header-navigation ul.navigation-box>li.current>a,
    .header-navigation ul.navigation-box>li:hover>a {
        color: #ffffff;
        text-shadow: 1px 0 0 rgba($color: #ffffff, $alpha: .8);

    }


    .header__cta-btn {
        color: #fff;
        background-image: none;        
        border-radius: 5px;
        overflow: hidden;

        &:before {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparent;
            border: 2px solid;
            border-image: linear-gradient(90deg, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 100%);
            border-image-slice: 1;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(90deg, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 100%);
            border-radius: 5px;
            opacity: 0;
            transition: all .4s ease;
            z-index: 0;
        }
        span {
            z-index: 2;
        }

        &:hover {
            &:before {
                transform: scaleX(1);
            }
            &:after {
                opacity: 1;
            }
        }
    }

    .stricked-menu {
        background-color: $thm-black;
    }
}