.sidebar {
    @media(max-width: 991px) {
        margin-top: 50px;
    }

    &__title {
        margin: 0;
        color: $thm-black;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
    }

    &__single:not(&__search) {
        padding: 50px;
        border-style: solid;
        border-width: 1px;
        border-color: #efefef;
        border-radius: 5px;

        @media (max-width: 1199px) {
            padding: 30px;
        }
    }


    &__single+&__single {
        margin-top: 30px;
    }

    &__post {
        &__single+&__single {
            margin-top: 30px;
        }

        &-image,
        &-content {
            display: table-cell;
            vertical-align: middle;
        }

        &-image {
            width: 60px;

            .inner-block {
                width: 100%;

                >img {
                    width: 100%;
                    border-radius: 50%;
                }
            }
        }

        &-content {
            padding-left: 20px;
        }

        &-title {
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: $thm-text-color;

            a {
                color: inherit;
                transition: all .4s ease;

                &:hover {
                    color: $thm-black;
                }
            }
        }
    }

    &__category-list {
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {

            line-height: 1em;

            a {
                color: #74727a;
                font-size: 16px;
                font-weight: 400;
                display: block;
                position: relative;
                transition: all .4s ease;

                &:before {
                    content: '\f105';
                    font-family: 'FontAwesome';
                    color: #7b7b8c;
                    font-weight: 900;
                    font-size: 14px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    transition: all .4s ease;
                }

                &:hover {
                    color: $thm-base-color;
                }
            }
        }

        &-item+&-item {
            margin-top: 27px;
        }
    }

    &__tags-list {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: -10px;

        &-item {
            display: inline-block;
            vertical-align: middle;

            line-height: 1em;

            a {
                display: block;
                color: $thm-text-color;
                font-size: 16px;
                font-weight: 400;
                line-height: 1em;
                transition: all .4s ease;

                &:hover {
                    color: $thm-base-color-2;
                }
            }
        }
    }

    &__search-form {
        width: 100%;
        height: 83px;
        background-image: linear-gradient(40deg,$thm-base-color 0%, $thm-base-color-2 100%);
        position: relative;
        border-radius: 5px;

        input {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            color: #fff;
            padding-left: 40px;
            padding-right: 40px;

            @include placeholder {
                color: #fff;
            }
        }

        button[type=submit] {
            background-color: transparent;
            border: none;
            outline: none;
            position: absolute;
            top: 50%;
            right: 50px;
            transform: translateY(-50%);
            cursor: pointer;
            padding: 0;
            transition: all .4s ease;


            i {
                font-size: 16px;
                color: #fff;
                transition: all .4s ease;
            }
        }
    }
}