.banner-one {
  position: relative;
  background-image: url(../images/background/banner-bg-1-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  .container {
    padding-top: 350px;
    padding-bottom: 230px;
    position: relative;
  }

  &__title {
    margin: 0;
    color: $thm-black;
    font-weight: 300;
    font-size: 90px;
    line-height: 1em;
    letter-spacing: -0.06em;

    span {
      font-weight: 500;
    }
  }

  &__text {
    margin: 0;
    font-size: 20px;
    line-height: 36px;
    color: #74727a;
    margin-top: 25px;
    margin-bottom: 40px;
  }

  &__btn {
    padding: 18px 55px;
  }

  &__moc {
    position: absolute;
    bottom: 7%;
    right: -30px;

    &:before {
      content: '';
      width: 639px;
      height: 639px;
      background-image: linear-gradient(40deg, rgb(255, 67, 192) 0%, rgb(255, 169, 92) 100%);
      box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.8);
      border-radius: 50%;
      position: absolute;
      top: -14%;
      left: -24%;
      // transform: translateX(-50%) translateY(-15%);
      transform-origin: center bottom;
      animation-fill-mode: both;
      animation-name: bounce;
      animation-timing-function: ease-out;
      animation-duration: 5s;
      animation-iteration-count: infinite;
    }

    img {
      position: relative;
      animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
    }
  }
}

[class*='banner-one__shape-'] {
  position: absolute;
  border-radius: 50%;
  animation-name: bubbleMover;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.banner-one__shape-1 {
  background-image: linear-gradient(40deg, rgb(13, 184, 255) 0%, rgb(243, 50, 255) 100%);
  box-shadow: 0px 20px 60px 0px rgba(141, 142, 255, 0.8);
  width: 143px;
  height: 143px;
  top: 5%;
  left: 3%;
  animation-duration: 36s;
  perspective: 150px;
}

.banner-one__shape-2 {
  background-image: linear-gradient(40deg, rgb(57, 174, 255) 0%, rgb(60, 255, 83) 100%);
  box-shadow: 0px 20px 40px 0px rgba(58, 212, 173, 0.8);
  width: 85px;
  height: 85px;
  top: 12%;
  left: 37%;
  animation-duration: 30s;
  perspective: 85px;
}

.banner-one__shape-3 {
  background-image: linear-gradient(40deg, rgb(255, 67, 192) 0%, rgb(255, 169, 92) 100%);
  box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.8);
  width: 85px;
  height: 85px;
  bottom: 12%;
  left: 6%;
  animation-duration: 21s;
  perspective: 85px;
}

.banner-one__shape-4 {
  background-image: linear-gradient(40deg, rgb(255, 112, 62) 0%, rgb(255, 236, 78) 100%);
  box-shadow: 0px 20px 40px 0px rgba(255, 178, 71, 0.8);
  width: 85px;
  height: 85px;
  top: 20%;
  right: 4%;
  animation-duration: 36s;
  perspective: 85px;
}

@keyframes bubbleMover {
  0% {
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    transform: translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: center center;
  }

  50% {
    transform: translateY(50px) translateX(100px) rotate(45deg);
    transform-origin: right bottom;
  }

  80% {
    transform: translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: left top;
  }

  100% {
    transform: translateY(0px) translateX(0) rotate(0);
    transform-origin: center center;
  }
}

.banner-two {
  position: relative;
  background-color: $thm-black;
  background-image: url(../images/background/banner-2-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  margin-bottom: 120px;

  .container {
    padding-top: 260px;
    padding-bottom: 120px;
    position: relative;
  }

  &__title {
    margin: 0;
    color: #fff;
    font-weight: 300;
    font-size: 90px;
    line-height: 1em;
    letter-spacing: -0.06em;

    span {
      font-weight: 500;
    }
  }

  &__text {
    margin: 0;
    font-size: 20px;
    line-height: 36px;
    color: rgba(#fff, 0.7);
    margin-top: 25px;
    margin-bottom: 40px;
  }

  &__btn {
    color: #fff;
    background-image: none;
    border-radius: 5px;
    overflow: hidden;
    padding: 16px 55px;

    &:before {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      border: 2px solid;
      border-image: linear-gradient(90deg, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 100%);
      border-image-slice: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(90deg, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 100%);
      border-radius: 5px;
      opacity: 0;
      transition: all 0.4s ease;
      z-index: 0;
    }

    span {
      z-index: 2;
    }

    &:hover {
      &:before {
        transform: scaleX(1);
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &__moc {
    position: absolute;
    bottom: 0;
    right: 0px;

    img {
      position: relative;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.5;
      top: 0;
      left: 0;
    }
  }
}

[class*='banner-two__shape-'] {
  position: absolute;
  border-radius: 50%;
  filter: blur(5px);
  animation-name: bubbleMover;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.banner-two__shape-1 {
  background-image: linear-gradient(40deg, rgb(13, 184, 255) 0%, rgb(243, 50, 255) 100%);

  width: 143px;
  height: 143px;
  top: 5%;
  left: 3%;
  animation-duration: 32s;
  perspective: 150px;
}

.banner-two__shape-2 {
  background-image: linear-gradient(40deg, rgb(57, 174, 255) 0%, rgb(60, 255, 83) 100%);

  width: 85px;
  height: 85px;
  top: 12%;
  left: 37%;
  animation-duration: 30s;
  perspective: 85px;
}

.banner-two__shape-3 {
  background-image: linear-gradient(40deg, rgb(255, 67, 192) 0%, rgb(255, 169, 92) 100%);

  width: 85px;
  height: 85px;
  bottom: 12%;
  left: 6%;
  animation-duration: 21s;
  perspective: 85px;
}

.banner-two__shape-4 {
  background-image: linear-gradient(40deg, rgb(255, 112, 62) 0%, rgb(255, 236, 78) 100%);

  width: 85px;
  height: 85px;
  top: 20%;
  right: 4%;
  animation-duration: 36s;
  perspective: 85px;
}
