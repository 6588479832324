@import 'react-modal-video/scss/modal-video.scss', './assets/scss/style.scss',
  './assets/scss/responsive.scss';

$main-header-height: 60px;

body,
html,
#root {
  width: 100%;
  height: 100%;
}

.copyright-links {
  margin-top: 12px;
  color: rgba(255, 255, 255, 0.5);

  a {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;

    &:focus,
    &:hover,
    &:active {
      color: #fff;
    }
  }
}

.main-header {
  width: 100%;
  height: $main-header-height;
  padding: 10px 0;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  z-index: 1;
  position: relative;

  .logo {
    height: 40px;
    padding: 0 10px;
    display: inline-block;

    img {
      height: 40px;
    }
  }

  nav {
    height: 100%;
    align-self: center;

    a {
      height: 100%;
      padding: 1rem;
      margin-left: 1rem;
    }
  }
}

.main-body {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.hubspot-form {
  margin-top: 1.75rem;
}

.about-page {
  &__founders-photo {
    text-align: center;
    width: 100%;
  }

  &__download {
    padding-top: 1rem !important;
    padding-bottom: 4rem !important;
  }
}

.always-visible,
.always-visible * {
  opacity: 100 !important;
}

// Mobile navigation
.mobile-navigation {
  &__list {
    text-align: right;
  }

  @media (max-width: 480px) {
    &__list {
      position: absolute;
      right: 0;
      top: $main-header-height - 10px;
      width: 100vw;
      background: white;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
      z-index: 1;
      opacity: 0;
      max-height: 0;
      padding: 2rem 0;
      text-align: left;

      transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

      &--visible {
        opacity: 100;
        max-height: inherit;
      }

      a {
        margin: 0 0 1rem !important;
        padding: 1rem 2rem !important;
        width: 100%;
        display: block;
        font-size: 2rem;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.navbar-brand {
  height: 55px;

  img {
    height: 100%;
  }
}

#ts-footer {
  .container {
    position: relative;
    top: -30px;
  }
}

#footer-links {
  a {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

iframe {
  background: transparent;
}
