$accordion-hover-border-color: #ffa065;

.faq-accordion {
  .accordion {
    cursor: pointer;
    outline: none;

    &:not(.active) {
      &:focus,
      &:hover {
        .accordion-inner {
          border-color: $accordion-hover-border-color;
        }
      }
    }
  }
}
