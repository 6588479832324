@import 'helper';

/*
* expandable menu started
*/

@media (max-width: 1199px) {
  .header-navigation {
    margin-bottom: 0;
  }

  .logo-box {
    width: 100%;
    float: none;
    margin: 0 !important;
  }

  .navigation-box {
    margin: 0;
  }

  .navbar-expand-lg .menu-toggler {
    display: block;
    margin-right: 0;
    border-color: #fff;
    background-color: transparent !important;
    margin: 23px 0;
    color: #fff;
    border: none;
    font-size: 28px;
    float: right;
    outline: none;
    cursor: pointer;
  }

  .menu-toggler:hover {
    border-color: $thm-base-color;
  }

  .menu-toggler .icon-bar {
    background: #fff;
  }

  .menu-toggler:hover .icon-bar {
    background: $thm-base-color;
  }

  .navbar-expand-lg .navbar-collapse {
    padding-bottom: 0;
    overflow: auto !important;
    width: 100%;
  }

  .navbar-expand-lg .navbar-collapse {
    display: none !important;
    background: transparent;
    padding: 0px 0px !important;
    padding-bottom: 0px !important;
    margin: 0;
    background: #000;
    transition: all 0.5s ease;
    height: 0;
  }

  .navbar-expand-lg .navbar-collapse.showen {
    display: block !important;
    height: auto;
    max-height: 70vh;
  }

  .navbar-collapse.show {
    overflow-y: auto;
  }

  .header-navigation .main-navigation {
    float: none !important;
    text-align: left !important;
  }

  .header-navigation .nav {
    width: 100%;
    text-align: left;
  }

  .header-navigation .nav > li {
    display: block;
    padding: 0 !important;
    width: 100%;
    float: none;
  }

  .header-navigation .nav > li + li {
    margin-left: 0 !important;
  }

  .header-navigation .nav > li > a {
    padding: 8px 0;
  }

  .header-navigation .right-box.nav {
    width: 100%;
    margin: 0;
  }

  .header-navigation .right-box.nav > li > a {
    border: none !important;
    padding: 8px 0;
    color: #fff;
  }

  .header-navigation .nav > li > a:after {
    display: none;
  }

  .header-navigation .nav.navigation-box + .nav {
    border-top: 1px dashed $thm-black;
  }

  .header-navigation .navigation-box > li > .sub-menu,
  .header-navigation .navigation-box > li > .sub-menu > li > .sub-menu {
    position: relative !important;
    width: 100% !important;
    opacity: 1 !important;
    visibility: visible !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    transition: none !important;
    display: none;
    float: none !important;
    margin: 0;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .header-navigation .navigation-box > li > .sub-menu > li > a::after {
    display: none;
  }

  .header-navigation ul.navigation-box > li + li {
    padding: 0 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: block;
    float: none;
    margin: 0 !important;
  }

  .header-navigation .nav > li.show-mobile {
    display: none;
  }

  .header-navigation ul.navigation-box > li > a,
  .header-navigation .right-box.nav > li > a {
    padding: 0;
    display: block;
    color: #fff;
    padding: 16px 0 16px 20px !important;
    transition: background 0.4s ease 0s;
  }

  .header-navigation ul.navigation-box li a .sub-nav-toggler {
    background-color: transparent;
    background-image: none;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 9px 9px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    /*margin-top: 8px;*/
    margin-right: 0px;
    display: block;
    cursor: pointer;
    outline: none;
  }

  .header-navigation .navigation-box .sub-nav-toggler .icon-bar {
    background-color: #fff;
    border-radius: 1px;
    display: block;
    height: 1px;
    width: 20px;
  }

  .header-navigation .navigation-box .sub-nav-toggler .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .header-navigation .container .right-side-box {
    top: 20px;
    right: 15px;
    transform: translateY(0%);
  }

  .header-navigation .container {
    display: block;
    padding-right: 0px;
    padding-left: 0px;
    position: relative;
  }

  .header-navigation .container .logo-box {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    float: none;
    padding-right: 145px;
  }

  .header-navigation .container .logo-box .navbar-brand {
    float: left;
    padding: 20px 0;
  }

  .header-navigation .container .logo-box .navbar-toggler {
    float: left;
  }

  .header-navigation ul.navigation-box {
    display: block;
    margin: 0 !important;
    width: 100%;
  }

  .header-navigation ul.navigation-box > li {
    padding: 0;
    display: block;
  }

  .header-navigation ul.navigation-box > li > a {
    display: block;
    padding: 13px 30px;
  }

  .header-navigation ul.navigation-box > li > a:after {
    display: none;
  }

  .header-navigation ul.navigation-box > li + li {
    margin: 0;
  }

  .header-navigation .main-navigation {
    float: none;
    width: 100%;
    display: none;
    text-align: left;
    background: #18212e;
    max-height: 70vh;
    overflow-y: scroll;
  }

  .header-navigation .container .menu-toggler {
    display: block;
  }

  .header-navigation.stricky-fixed ul.navigation-box > li {
    padding: 0;
  }

  .header-navigation .container .logo-box .navbar-brand {
    background-color: transparent;
  }

  .header-navigation .container .menu-toggler {
    float: left;
    margin: 28px 0;
    color: $thm-black;
    margin-left: 30px;
    position: relative;
  }

  .header-navigation .container .logo-box {
    padding-right: 15px !important;
  }

  .header-navigation {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header-navigation .container .logo-box {
    left: 0 !important;
  }

  .header-navigation .container .logo-box {
    padding-left: 15px;
  }

  .header-navigation .main-navigation {
    padding-right: 0;
  }
  .header-navigation ul.navigation-box > li.current > a,
  .header-navigation ul.navigation-box > li:hover > a {
    color: #fff;
  }

  .site-header__header-two .header-navigation .container .menu-toggler {
    color: #fff;
  }

  .site-header__header-two .header-navigation ul.navigation-box > li > a {
    color: #fff;
  }
}

@media (max-width: 425px) {
  .header-navigation .container .right-side-box {
    display: none !important;
  }

  .header-navigation .container .menu-toggler {
    float: right !important;
  }

  .header-navigation .container .logo-box {
    display: flex;
    justify-content: space-between;

    &:after {
      display: none;
    }
  }
}

@media (max-width: 1199px) {
  .blog-one__content {
    padding-left: 37px;
    padding-right: 37px;
  }

  .cta-one__moc {
    left: -20%;
  }
  .cta-two__moc {
    right: -70px;
  }

  .banner-two__moc {
    right: -29%;
  }

  .footer-widget__about {
    text-align: center;
  }

  .footer-widget {
    margin-bottom: 30px;
    padding: 0;
  }

  .footer-widget__links-wrap {
    padding-left: 15px;
  }

  .site-footer__upper {
    padding-bottom: 90px;
  }

  .site-footer__social {
    justify-content: center;
  }
}

@media (max-width: 991px) {
  img {
    max-width: 100%;
  }

  .fact-one__single {
    margin-bottom: 20px;
  }

  .app-shot-one .app-shot-one__carousel {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 20px;
  }

  .banner-one__moc {
    display: none;
  }
  .banner-one .container {
    padding-top: 200px;
    padding-bottom: 120px;
  }

  .cta-one__moc {
    position: relative;
    top: auto;
    left: auto;
  }
  .cta-two__moc {
    position: relative;
    top: auto;
    right: auto;
  }
  .pricing-one [class*='col-'] {
    margin-bottom: 0;
  }
  .pricing-one [class*='col-'] + [class*='col-'] {
    border-left: 0;
    border-top: 1px solid #efefef;
    padding-top: 40px;
    margin-top: 40px;
  }

  .testimonials-one__single p br {
    display: none;
  }
  .testimonials-one__single {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .fact-one__single {
    margin-left: auto;
    margin-right: auto;
  }

  .banner-two__moc {
    right: -52%;
  }
}

@media (max-width: 767px) {
  .blog-details .share-block {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .blog-details .social-block {
    margin-top: 20px;
  }
  .comment-one__top-left,
  .comment-one__top-right,
  .blog-details__author-image,
  .blog-details__author-content {
    display: block;
  }
  .comment-one__top-right,
  .blog-details__author-content {
    padding: 0;
    padding-top: 20px;
  }

  .video-one__title {
    font-size: 30px;
  }

  .banner-two__moc {
    display: none;
  }

  .banner-two .container {
    padding-top: 150px;
  }
}

@media (max-width: 575px) {
  .footer-widget__links-wrap {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-widget__about {
    text-align: left;
  }
  .inner-banner {
    padding-top: 150px;
    padding-bottom: 120px;
  }
}

@media (max-width: 480px) {
  .block-title__title,
  .banner-one__text,
  .banner-two__text {
    br {
      display: none;
    }
  }

  .block-title__title {
    font-size: 40px;
    line-height: 1.1em;
  }

  .comment-one__top-left,
  .comment-one__top-right,
  .comment-one__image,
  .comment-one__content,
  .blog-details__author-image,
  .blog-details__author-content {
    display: block;
    padding: 0;
  }

  .comment-one__content,
  .comment-one__top-right,
  .blog-details__author-content {
    margin-top: 20px;
  }

  .blog-details .blog-one__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .blog-details__author {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  [class*='banner-one__shape-'],
  [class*='banner-two__shape-'] {
    display: none;
  }

  .banner-one__moc {
    display: block;
    position: relative;
    top: auto;
    right: auto;
  }
  .banner-two__title,
  .banner-one__title {
    font-size: 50px;
    br {
      display: none;
    }
  }
}

@media (max-width: 375px) {
  .blog-one__meta li + li:before {
    margin-left: 3px;
    margin-right: 3px;
  }
  .block-title__title {
    font-size: 36px;
  }

  .site-footer__bottom .inner-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .site-footer__social {
    margin-top: 15px;
  }
  .banner-one .container {
    padding-top: 130px;
  }
  .banner-one__moc:before {
    display: none;
  }

  .video-one__btn {
    width: 60px;
    height: 60px;

    i {
      font-size: 20px;
      line-height: 60px;
    }
  }
  .video-one__title {
    font-size: 24px;
    margin-top: 15px;
  }
}
