.video-one {

    position: relative;

    &__bg {
        position: absolute;
        bottom: 0%;
        left: 0;
    }

    .container {
        position: relative;
        padding: 15px;
        background-image: linear-gradient(0deg, #ffa95c 0%, #ff43c0 100%);
        border-radius: 7px;
        z-index: 10;
    }

    &__box {
        position: relative;
        border-radius: 7px;

        >img {
            width: 100%;
            border-radius: 7px;
        }

        &:hover {
            .video-one__content {
                background-color: rgba($thm-black, .2);
            }
        }
    }

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($thm-black, .6);
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        transition: all .4s ease;
        border-radius: 7px;
    }

    &__btn {
        width: 106px;
        height: 106px;
        background-image: linear-gradient(to right, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 51%, rgb(255, 78, 181) 100%);
        background-size: 200% auto;
        text-align: center;
        border-radius: 50%;
        transition: all .4s ease;

        box-shadow: 0px 20px 40px 0px rgba(42, 40, 51, 0.8);

        i {
            font-size: 24px;
            color: #fff;
            line-height: 106px;
        }

        &:hover {
            background-position: right center;

            i {
                color: #fff;
            }
        }
    }

    &__title {
        font-size: 50px;
        font-weight: 400;
        color: #fff;
        margin: 0;
        margin-top: 20px;

        span {
            font-weight: 700;
        }
    }
}