.mailchimp-one {
	padding-top: 110px;
	padding-bottom: 120px;
    background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
	
	.block-title__title {
		color: #fff;
	}

    &__mc-form {
        width: 100%;
        max-width: 692px;
        height: 91px;
        background-color: #fff;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        input[type="email"] {
            border: none;
            outline: none;
            background-color: transparent;
            display: block;
            width: 100%;
            height: 100%;
            padding-left: 40px;
            font-size: 16px;
            color: #74727a;
            font-weight: 400;

            @include placeholder {
                color: #74727a;
            }
        }

        button[type=submit] {
            border: none;
            outline: none;
            width: 61px;
            height: 61px;
            background-image: linear-gradient(to right, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 51%, rgb(255, 78, 181) 100%);
    		background-size: 200% auto;
            border-radius: 50%;
            font-size: 22px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            transition: all .4s ease;
            &:hover {
        		background-position: right center;
        	}
        }

        &.errored {
            input {
                background-color: red;
                color: #fff;

                @include placeholder() {
                    color: #fff;
                }
            }
        }
    }
}



.mc-form__response {


    p {
        background-color: #fff;
        font-size: 11px;
        margin: 0;
        color: $thm-black;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
}

.mc-form__response.successed {
    p {
        background-color: green;
    }

}