.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-close {
  background: transparent;
  border: none;
  padding: 0.25rem 1rem;
  font-size: 2rem;

  &:focus,
  &:hover,
  &:active {
    background: transparent;
    color: #333;
  }
}
